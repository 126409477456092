import { ProviderOutput } from '@coverright/data-access/types/medicare';
import { useSaveMedicareQuoteMutation } from '../../../shared/api/medicare';
import { useAppSelector } from '../../../shared/hooks';
import { medicareQuote } from '../../ma-quote';
import { useLogEvent } from '@coverright/shared/analytics';
import { useSnackbar } from 'notistack';

export function useSaveDoctors() {
  const [mutation, { isLoading, error }] = useSaveMedicareQuoteMutation();
  const quote = useAppSelector(medicareQuote);
  const logEvent = useLogEvent();
  const { enqueueSnackbar } = useSnackbar();

  const save = async (doctors: ProviderOutput[]) => {
    if (quote) {
      if ((window as any).track && doctors.length) {
        logEvent('Doctors Added', {
          doctors: doctors.map((d) => ({
            npi: d.npi,
            addresses: d.addresses.map((a) => a.id),
          })),
          location: 'Plan finder',
        });
      }

      try {
        await mutation({
          data: {
            preferredDoctors: doctors.map((d) => ({
              npi: d.npi,
              addresses: d.addresses.map((a) => ({ addressId: a.id })),
            })),
            planYear: quote.planYear,
            county: quote.county,
            zip: quote.zip,
            id: quote.id,
          },
        }).unwrap();
        enqueueSnackbar('Doctors has been saved', { variant: 'success' });
      } catch (e) {
        enqueueSnackbar('Error saving doctors', { variant: 'error' });
      }
    } else {
      throw Error('Medicare quote is not exists');
    }
  };

  return { save, isLoading };
}
