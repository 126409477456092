import { Box, Typography } from '@mui/material';
import * as _ from 'lodash';
import {
  getNumbersFromString,
  GetPlansYear,
  getStoredUser,
  isPersonEligible,
} from '@coverright/utils';
import {
  MedigapPlanName,
  MedigapPremiumRange,
} from '@coverright/data-access/types/medigap';
import {
  MedicarePlansAggregationOutput,
  MedicarePlanType,
} from '@coverright/data-access/types/medicare';
import { getDeductible } from '@coverright/utils';
import React from 'react';

export enum PlanType {
  MG,
  PPO,
  HMO,
  OM,
  HDMG,
  DSNP,
  MMP,
}

export interface IPlansData {
  medicarePlanData: {
    [key in MedicarePlanType]: MedicarePlansAggregationOutput;
  };
  medigapPremiumRanges: MedigapPremiumRange[];
}

export const calculateRecommended = (
  v1?: boolean,
  v2?: boolean,
  v3?: boolean,
  v4?: boolean,
  plansData?: IPlansData
): [PlanType[], boolean, boolean] => {
  const recommended = calculateRecommendedInternal(v1, v2, v3, v4, plansData);
  let flag = false;

  if (
    recommended[0].includes(PlanType.HMO) &&
    !plansData?.medicarePlanData?.HMO?.monthlyPremiumMin
  ) {
    recommended[0] = _.without(recommended[0], PlanType.HMO);
    flag = true;
  }
  if (
    recommended[0].includes(PlanType.PPO) &&
    !plansData?.medicarePlanData?.PPO?.monthlyPremiumMin
  ) {
    recommended[0] = _.without(recommended[0], PlanType.PPO);
    flag = true;
  }
  return [...recommended, flag];
};

const calculateRecommendedInternal = (
  v1?: boolean,
  v2?: boolean,
  v3?: boolean,
  v4?: boolean,
  plansData?: IPlansData
): [PlanType[], boolean] => {
  const mask = [v1, v2, v3].map((v) => (v ? 1 : 0)).join('');

  const alternatives = calculateAlternatives(v1, v2, v3);

  for (const index in values) {
    if (values[index].some((v) => v === mask)) {
      const type = parseFloat(index) as PlanType;
      if (v4) {
        if (plansData?.medicarePlanData?.HMO?.dsnpPlansExist) {
          if (plansData?.medicarePlanData?.HMO?.mmpHmoPlansExist) {
            return [
              [
                PlanType.DSNP,
                PlanType.MMP,
                PlanType.HMO,
                PlanType.PPO,
                PlanType.OM,
              ],
              true,
            ];
          } else {
            return [
              [PlanType.DSNP, PlanType.HMO, PlanType.PPO, PlanType.OM],
              true,
            ];
          }
        } else if (plansData?.medicarePlanData?.HMO?.mmpHmoPlansExist) {
          return [
            [PlanType.MMP, PlanType.HMO, PlanType.PPO, PlanType.OM],
            true,
          ];
        } else {
          return [[PlanType.HMO, PlanType.PPO, PlanType.OM], true];
        }
      }
      if (
        index === '1' &&
        plansData?.medicarePlanData &&
        plansData?.medigapPremiumRanges
      ) {
        const MOOP = getNumbersFromString(
          plansData.medicarePlanData?.PPO?.maxOutOfPocketOutOfNetworkMin || ''
        )[0];
        const MGPremium = plansData.medigapPremiumRanges
          .filter(
            (p) =>
              ![MedigapPlanName.Hdf, MedigapPlanName.Hdg].includes(p.planName)
          )
          .reduce(
            (previousValue: number, currentValue: MedigapPremiumRange) =>
              _.max([
                previousValue,
                ...getNumbersFromString(currentValue.rangeDescription),
              ]) as number,
            0
          );
        const HDMGPremium = plansData.medigapPremiumRanges
          .filter((p) =>
            [MedigapPlanName.Hdf, MedigapPlanName.Hdg].includes(p.planName)
          )
          .reduce(
            (previousValue: number, currentValue: MedigapPremiumRange) =>
              _.min([
                previousValue,
                ...getNumbersFromString(currentValue.rangeDescription),
              ]) as number,
            0
          );
        if (mask === '101' || mask === '111') {
          if (
            MOOP >=
            1.6 *
              (MGPremium * 12 +
                getNumbersFromString(
                  getDeductible('annualPartBDeductible', GetPlansYear())
                )[0])
          ) {
            return [
              [
                PlanType.MG,
                PlanType.PPO,
                PlanType.HDMG,
                PlanType.HMO,
                PlanType.OM,
              ],
              true,
            ];
          }
        } else {
          if (
            MOOP >=
            3 *
              (HDMGPremium * 12 +
                getNumbersFromString(
                  getDeductible('deductible', GetPlansYear())
                )[0])
          ) {
            return [
              [PlanType.HDMG, PlanType.PPO, PlanType.HMO, PlanType.OM],
              true,
            ];
          }
        }
      }
      return [[type, ...alternatives], false];
    }
  }

  return [[PlanType.HMO, ...alternatives], false];
};

const calculateAlternatives = (
  v1?: boolean,
  v2?: boolean,
  v3?: boolean
): PlanType[] => {
  const mask = [v1, v2, v3].map((v) => (v ? 1 : 0)).join('');
  switch (mask) {
    case '111':
      return [PlanType.MG, PlanType.HDMG, PlanType.HMO, PlanType.OM];

    case '110':
      return [PlanType.PPO, PlanType.HDMG, PlanType.HMO, PlanType.OM];

    case '101':
      return [PlanType.MG, PlanType.HMO, PlanType.HDMG, PlanType.OM];

    case '100':
      return [PlanType.HDMG, PlanType.PPO, PlanType.HMO, PlanType.OM];

    case '000':
      return [PlanType.PPO, PlanType.HDMG, PlanType.OM];

    case '011':
    case '010':
      return [PlanType.HDMG, PlanType.HMO, PlanType.OM];

    case '001':
      return [PlanType.PPO, PlanType.HDMG, PlanType.OM];

    default:
      return [];
  }
};

export const calculateExplanations = (
  v1?: boolean,
  v2?: boolean,
  v3?: boolean,
  v4?: boolean,
  plansData?: IPlansData,
  birthDate = getStoredUser()?.birthDate
): [boolean, Explanation][] => {
  const mask = [v1, v2, v3].map((v) => (v ? 1 : 0)).join('');
  const recommended = calculateRecommended(v1, v2, v3, v4, plansData);
  const recPlanType = recommended[0][0];
  const overwritten = recommended[1];
  const noPrices = recommended[2];
  const medicareOverwrite: [boolean, Explanation][] = [];
  let premium: any;
  if (plansData?.medigapPremiumRanges) {
    if (recPlanType === PlanType.HDMG) {
      premium = Math.round(
        _.min(
          getNumbersFromString(
            plansData?.medigapPremiumRanges
              .filter((i) =>
                [MedigapPlanName.Hdf, MedigapPlanName.Hdg].includes(i.planName)
              )
              .filter((i) =>
                isPersonEligible(birthDate)
                  ? true
                  : i.planName !== MedigapPlanName.Hdf
              )
              .map((p) => p.rangeDescription || '')
              .join('|')
          )
        ) as number
      );
    } else {
      premium = Math.round(
        _.min(
          getNumbersFromString(
            plansData?.medigapPremiumRanges
              .filter((i) =>
                [
                  MedigapPlanName.N,
                  MedigapPlanName.G,
                  MedigapPlanName.F,
                ].includes(i.planName)
              )
              .filter((i) =>
                isPersonEligible(birthDate)
                  ? true
                  : i.planName !== MedigapPlanName.F
              )
              .map((p) => p.rangeDescription || '')
              .join('|')
          )
        ) as number
      );
    }
  }
  let MApremium: any;
  if (plansData?.medicarePlanData) {
    switch (recPlanType) {
      case PlanType.HMO:
        MApremium = plansData?.medicarePlanData?.HMO.monthlyPremiumMin;
        break;
      case PlanType.PPO:
        MApremium = plansData?.medicarePlanData?.PPO.monthlyPremiumMin;
        break;
      default:
        MApremium = plansData?.medicarePlanData?.MMP_HMO.monthlyPremiumMin;
    }
  }

  let type = '';
  if (v4) {
    if (plansData?.medicarePlanData?.HMO.dsnpPlansExist) {
      medicareOverwrite.push([true, overwrittenMedicareMessages(noPrices)[0]]);
      type = 'D-SNP';
    } else if (plansData?.medicarePlanData?.HMO.mmpHmoPlansExist) {
      medicareOverwrite.push([true, overwrittenMedicareMessages(noPrices)[1]]);
      type = 'MMP';
    } else {
      medicareOverwrite.push([true, overwrittenMedicareMessages(noPrices)[2]]);
      type = noPrices ? 'OM' : 'HMO';
    }
  }
  switch (mask) {
    case '111':
      return overwritten
        ? [
            ...medicareOverwrite,
            [
              true,
              noPrices
                ? noPricesPrepayMessages(recPlanType, premium)
                : overwrittenPrepayMessages(type, v4)[0],
            ],
            [!v4, overwrittenFlexMessages(type, v4)[2]],
            [!!v4, overwrittenBenefitsMessages(type)[2]],
          ]
        : [
            [
              true,
              noPrices
                ? noPricesPrepayMessages(recPlanType, premium)
                : prepayMessages([recPlanType, premium])[1],
            ],
            [true, flexMessages(recPlanType)[4]],
            [true, benefitsMessages(recPlanType)[2]],
          ];
    case '110':
      return overwritten
        ? [
            ...medicareOverwrite,
            [
              true,
              noPrices
                ? noPricesPrepayMessages(recPlanType, premium)
                : overwrittenPrepayMessages(type, v4)[0],
            ],
            [false, overwrittenFlexMessages(type, v4)[0]],
            [true, overwrittenBenefitsMessages(type)[1]],
          ]
        : [
            [
              true,
              noPrices
                ? noPricesPrepayMessages(recPlanType, premium)
                : prepayMessages([recPlanType, premium])[0],
            ],
            [true, flexMessages(recPlanType)[0]],
            [true, benefitsMessages(recPlanType)[1]],
          ];
    case '101':
      return overwritten
        ? [
            ...medicareOverwrite,
            [
              true,
              noPrices
                ? noPricesPrepayMessages(recPlanType, premium)
                : overwrittenPrepayMessages(type, v4, premium)[0],
            ],
            [true, overwrittenFlexMessages(type, v4)[1]],
            [!!v4, overwrittenBenefitsMessages(type)[v4 ? 0 : 2]],
          ]
        : [
            [
              true,
              noPrices
                ? noPricesPrepayMessages(recPlanType, premium)
                : prepayMessages([recPlanType, MApremium])[1],
            ],
            [true, flexMessages(recPlanType)[1]],
            [true, benefitsMessages(recPlanType)[2]],
          ];
    case '100':
      return overwritten
        ? [
            ...medicareOverwrite,
            [
              true,
              noPrices
                ? noPricesPrepayMessages(recPlanType, premium)
                : overwrittenPrepayMessages(type, v4)[0],
            ],
            [true, overwrittenFlexMessages(type, v4)[1]],
            [true, overwrittenBenefitsMessages(type)[1]],
          ]
        : [
            [
              true,
              noPrices
                ? noPricesPrepayMessages(recPlanType, premium)
                : prepayMessages([recPlanType, premium])[0],
            ],
            [true, flexMessages(recPlanType)[2]],
            [true, benefitsMessages(recPlanType)[1]],
          ];
    case '000':
      return overwritten
        ? [
            ...medicareOverwrite,
            [
              true,
              noPrices
                ? noPricesPrepayMessages(recPlanType, premium)
                : overwrittenPrepayMessages(type, v4)[1],
            ],
            [true, overwrittenFlexMessages(type, v4)[1]],
            [true, overwrittenBenefitsMessages(type)[1]],
          ]
        : [
            [
              true,
              noPrices
                ? noPricesPrepayMessages(recPlanType, premium)
                : prepayMessages([recPlanType, MApremium])[1],
            ],
            [true, flexMessages(recPlanType)[3]],
            [true, benefitsMessages(recPlanType)[3]],
          ];
    case '011':
      return overwritten
        ? [
            ...medicareOverwrite,
            [
              true,
              noPrices
                ? noPricesPrepayMessages(recPlanType, premium)
                : overwrittenPrepayMessages(type, v4, premium)[1],
            ],
            [!v4, overwrittenFlexMessages(type, v4)[0]],
            [!!v4, overwrittenBenefitsMessages(type)[v4 ? 0 : 3]],
          ]
        : [
            [
              true,
              noPrices
                ? noPricesPrepayMessages(recPlanType, premium)
                : prepayMessages([recPlanType, MApremium])[1],
            ],
            [true, flexMessages(recPlanType)[4]],
            [true, benefitsMessages(recPlanType)[2]],
          ];
    case '010':
      return overwritten
        ? [
            ...medicareOverwrite,
            [
              true,
              noPrices
                ? noPricesPrepayMessages(recPlanType, premium)
                : overwrittenPrepayMessages(type, v4, premium)[1],
            ],
            [!v4, overwrittenFlexMessages(type, v4)[0]],
            [true, overwrittenBenefitsMessages(type)[v4 ? 1 : 4]],
          ]
        : [
            [
              true,
              noPrices
                ? noPricesPrepayMessages(recPlanType, premium)
                : prepayMessages([recPlanType, MApremium])[1],
            ],
            [true, flexMessages(recPlanType)[4]],
            [true, benefitsMessages(recPlanType)[3]],
          ];
    case '001':
      return overwritten
        ? [
            ...medicareOverwrite,
            [
              true,
              noPrices
                ? noPricesPrepayMessages(recPlanType, premium)
                : overwrittenPrepayMessages(type, v4)[1],
            ],
            [true, overwrittenFlexMessages(type, v4)[1]],
            [noPrices ? !v3 : true, overwrittenBenefitsMessages(type)[0]],
          ]
        : [
            [
              true,
              noPrices
                ? noPricesPrepayMessages(recPlanType, premium)
                : prepayMessages([recPlanType, MApremium])[1],
            ],
            [true, flexMessages(recPlanType)[3]],
            [true, benefitsMessages(recPlanType)[2]],
          ];

    default:
      return [];
  }
};

const noPricesPrepayMessages = (type: PlanType, premium?: any): Explanation => {
  switch (type) {
    case PlanType.HDMG:
      return {
        title:
          'You prefer to pay lower (or no) monthly premiums to save money and share out-of-pocket costs with your insurance company as you use healthcare services',
        text: `With a High Deductible Medicare Supplement plan, your monthly premium can be as low as ${premium}.  You pay 100% of your regular out-of-pocket costs obligations up to the plan's annual deductible and then $0 for the rest of the year.`,
      };
    case PlanType.OM:
      return {
        title:
          'You prefer to pay lower (or no) monthly premiums to save money and share out-of-pocket costs with your insurance company as you use healthcare services',
        text: `With a High Deductible Medicare Supplement plan, your monthly premium can be as low as ${premium}.  You pay 100% of your regular out-of-pocket costs obligations up to the plan's annual deductible and then $0 for the rest of the year.`,
      };
    case PlanType.MG:
      return {
        title:
          'You prefer to pay lower (or no) monthly premiums to save money and share out-of-pocket costs with your insurance company as you use healthcare services',
        text: `With a High Deductible Medicare Supplement plan, your monthly premium can be as low as ${premium}.  You pay 100% of your regular out-of-pocket costs obligations up to the plan's annual deductible and then $0 for the rest of the year.`,
      };
    default:
      return {
        title: '',
        text: ``,
      };
  }
};

const overwrittenMedicareMessages = (noPrices: boolean): Explanation[] => [
  {
    title:
      "You're receiving Medicaid, which makes you eligible for a Dual-Special Needs Plan (D-SNP).",
    text: `Since you're receiving Medicaid, you're eligible to enroll in a special type of Medicare Advantage plan called a Dual-Special Needs Plan (D-SNP). These plans are specifically designed to deliver integrated care for people who are on both Medicaid and Medicare. While you are receiving Medicaid, your healthcare costs will be covered based on the level of Medicaid help you are receiving.`,
  },
  {
    title:
      "You're receiving Medicaid, which means you may be eligible for a Medicare-Medicaid Plan (MMP) if you have 'full' Medicaid benefits",
    text: `Since you're receiving Medicaid, you may be eligible to enroll in a special type of Medicare Advantage plan called a Medicare-Medicaid Plan (MMP) if you have 'full' Medicaid benefits. These plans are specifically designed to deliver integrated care for people who are on both Medicaid and Medicare. While you are receiving Medicaid, your healthcare costs will be covered based on the level of Medicaid help you are receiving.`,
  },
  {
    title:
      "You're receiving Medicaid, which makes a 'dual-eligible' beneficiary meaning you can receive benefits of Medicare and Medicaid at the same time",
    text: noPrices
      ? 'If you are eligible for Medicaid, you can still receive your Medicaid benefits while using Medicare.  While you are receiving Medicaid, your healthcare costs will be covered based on the level of Medicaid help you are receiving.'
      : 'If you are eligible Medicaid, you can enroll in a Medicare Advantage HMO plan and still receive your Medicaid benefits while using Medicare.   HMO plans come with bundled drug coverage as well as extra benefits such as dental, vision and hearing.  While you are receiving Medicaid, your healthcare costs will be covered based on the level of Medicaid help you are receiving.',
  },
];

const overwrittenPrepayMessages = (
  type: string,
  isMedicaid?: boolean,
  premium?: any
): Explanation[] =>
  isMedicaid
    ? [
        {
          title:
            'You prefer to pay a monthly premium for comprehensive coverage, so you don’t have to worry about out-of-pocket costs when you use healthcare services',
          text: `With a Medicare Advantage {type} plan, you can get comprehensive coverage with $0 premiums as well as low-to-no out-of-pocket costs when using healthcare services.`,
        },
        {
          title:
            'You prefer to pay lower (or no) monthly premiums to save money and share out-of-pocket costs with your insurance company as you use healthcare services',
          text:
            type === 'OM'
              ? 'If you are eligible for Medicaid, your Medicaid benefits will help pay for some or all of your Medicare premiums depending on the level of Medicaid help you are receiving.'
              : `With a Medicare Advantage ${type} plan, your monthly premium is typically $0 with low-to-no out-of-pocket costs when using healthcare services.`,
        },
      ]
    : [
        {
          title:
            'You prefer to pay a monthly premium for comprehensive coverage, so you don’t have to worry about out-of-pocket costs when you use healthcare services',
          text: `With a Medicare Supplement plan, you can pay a ${premium} per month in your area to get a plan where you have minimal-to-no out-of-pocket costs when you use healthcare services.`,
        },
        {
          title:
            'You prefer to pay lower (or no) monthly premiums to save money and share out-of-pocket costs with your insurance company as you use healthcare services',
          text: `With a High Deductible Medicare Supplement plan, you can pay a ${premium} per month in your area to get a plan where you have no out-of-pocket costs after you reach an annual deductible of ${getDeductible(
            'deductible'
          )}.`,
        },
      ];

const overwrittenFlexMessages = (
  type: string,
  isMedicaid?: boolean
): Explanation[] =>
  isMedicaid
    ? [
        {
          title: 'You want the flexibility to see doctors outside of a network',
          titleColor: '#D32C1C',
          text: `To achieve the lowest costs, you are typically required to use a network of providers determined by the ${type} plan.`,
        },
        {
          title:
            'You’re okay with using a network of healthcare providers if it lowers costs',
          text:
            type === 'OM'
              ? 'With Original Medicare, you have the flexibility to see any doctor or visit any hospital in the U.S. that accepts Medicare.'
              : `Medicare Advantage ${type} plans use a network of doctors and providers. These plans help beneficiaries better coordinate their healthcare and keep costs down.`,
        },
      ]
    : [
        {
          title: 'You want the flexibility to see doctors outside of a network',
          text: `Under a High Deductible Medicare Supplement plan, you have the flexibility to see any doctor or visit any hospital in the U.S. that accepts Medicare.`,
        },
        {
          title:
            'You’re okay with using a network of healthcare providers if it lowers costs',
          text: `Under Medicare Supplement plan, you have the flexibility to see any doctor or visit any hospital in the U.S. that accepts Medicare.`,
        },
        {
          title: 'You want the flexibility to see doctors outside of a network',
          text: `Under a Medicare Supplement plan, you have the flexibility to see any doctor or visit any hospital in the U.S. that accepts Medicare.`,
        },
      ];

const overwrittenBenefitsMessages = (type: string): Explanation[] => [
  {
    title:
      "You're looking for a plan with extra benefits (such as dental, vision or hearing)",
    text: 'Most Medicare Advantage plans offer extra benefits such as dental, vision and hearing coverage for no additional premium.',
  },
  {
    title:
      "You're indifferent as to whether your plan includes extra benefits (such as dental, vision or hearing)",
    text:
      type === 'OM'
        ? "Original Medicare only provides medical/hospital coverage but typically won't cover extra benefits.  If these extra benefits are important to you, you can purchase standalone coverage."
        : 'Most Medicare Advantage plans offer extra benefits such as dental, vision and hearing coverage for no additional premium.',
  },
  {
    title:
      "You're looking for a plan with extra benefits (such as dental, vision or hearing)",
    titleColor: '#D32C1C',
    text: `A Medicare Supplement plan provides comprehensive medical/hospital coverage but typically won't cover extra benefits.  If these extra benefits are important to you, you can purchase standalone coverage or consider a Medicare Advantage plan (if available) instead.`,
  },
  {
    title:
      "You're looking for a plan with extra benefits (such as dental, vision or hearing)",
    titleColor: '#D32C1C',
    text: `A High Deductible Medicare Supplement will provide comprehensive medical/hospital coverage after you reach your annual deductible but typically won't cover extra benefits.  If these extra benefits are important to you, you can purchase standalone coverage or consider a Medicare Advantage (if available) plan instead.`,
  },
  {
    title:
      "You're indifferent as to whether you plan includes extra benefits (such as dental, vision or hearing)",
    text: "A High Deductible Medicare Supplement plan provides comprehensive medical/hospital coverage after your annual deductible but typically won't cover extra benefits.  You can purchase this coverage separately if required.",
  },
];

const prepayMessages = (data: any): Explanation[] => [
  {
    title:
      'You prefer to pay a monthly premium for comprehensive coverage, so you don’t have to worry about out-of-pocket costs when you use healthcare services',
    text: `With a Medicare Supplement plan, you can pay a $${data[1]} per month in your area to get a plan where you have minimal-to-no out-of-pocket costs when you use healthcare services.`,
  },
  data[0] === PlanType.PPO
    ? {
        title:
          'You prefer to pay a monthly premium for comprehensive coverage, so you don’t have to worry about out-of-pocket costs when you use healthcare services',
        text: 'With a Medicare Advantage PPO plan, you can typically pay a higher premium to lower your maximum annual out-of-pocket limit.',
      }
    : {
        title:
          'You prefer to pay lower (or no) monthly premiums to save money and share out-of-pocket costs with your insurance company as you use healthcare services',
        text: `With a ${planNameByType(
          data[0]
        )} plan, your monthly premium can be as low as ${
          data[1]
        }. You pay copays/coinsurance costs when you use services up to a maximum annual out-of-pocket cap`,
      },
];

const flexMessages = (type: PlanType): Explanation[] => {
  let message = '';

  if (type === PlanType.HDMG || type === PlanType.MG) {
    message =
      'Under Medicare Supplement plan, you have the flexibility to see any doctor or visit any hospital in the U.S. that accepts Medicare.';
  }

  return [
    {
      title: 'You want the flexibility to see doctors outside of a network',
      text:
        message ||
        'Under Medicare Supplement plan, you have the flexibility to see any doctor or visit any hospital in the U.S. that accepts Medicare.',
    },
    {
      title:
        'You’re okay with using a network of healthcare providers if it lowers costs',
      text:
        message ||
        "Medicare Advantage PPO plans allows you to see doctors out of the plan's standard network, however, you can save costs by staying within the plan's network.",
    },
    {
      title:
        'You’re okay with using a network of healthcare providers if it lowers costs',
      text:
        message ||
        'Great news! Under Medicare Supplement plan, because you are paying for comprehensive coverage, you have the flexibility to see any doctor or visit any hospital in the U.S. that accepts Medicare.',
    },
    {
      title:
        'You’re okay with using a network of healthcare providers if it lowers costs',
      text:
        message ||
        'Medicare Advantage HMO plans use a network of doctors and providers.  These plans help beneficiaries better coordinate their healthcare and keep costs down.',
    },
    {
      title: 'You want the flexibility to see doctors outside of a network',
      text:
        message ||
        "Medicare Advantage PPO plans allows you to see doctors out of the plan's standard network but at higher cost, there is typically no requirement for a dedicated primary care physician (PCP) or specialist referrals",
    },
  ];
};

const benefitsMessages = (type: PlanType): Explanation[] => {
  let message = '';

  switch (type) {
    case PlanType.HDMG:
      message =
        "A HIgh Deductible Medicare Supplement plan provides medical/hospital coverage but typically won't cover extra benefits.  If these extra benefits are important to you, you can purchase standalone coverage.";
      break;
    case PlanType.MG:
      message =
        "A Medicare Supplement plan provides comprehensive medical/hospital coverage but typically won't cover extra benefits.  If these extra benefits are important to you, you can purchase standalone coverage or consider a Medicare Advantage plan instead.";
      break;
    case PlanType.OM:
      message =
        "Original Medicare only provides medical/hospital coverage but typically won't cover extra benefits.  If these extra benefits are important to you, you can purchase standalone coverage.";
      break;
  }

  return [
    {
      title:
        "You're looking for a plan with extra benefits (such as dental, vision or hearing)",
      text:
        message ||
        "A Medicare Supplement plan provides comprehensive medical/hospital coverage but typically won't cover extra benefits.  If these extra benefits are important to you, you can purchase standalone coverage or consider a Medicare Advantage plan instead.",
    },
    {
      title:
        "You're indifferent as to whether your plan includes extra benefits (such as dental, vision or hearing)",
      text:
        message ||
        "A Medicare Supplement plan provides comprehensive medical/hospital coverage but typically won't cover extra benefits.  You can purchase this coverage separately if required.",
    },
    {
      title:
        "You're looking for a plan with extra benefits (such as dental, vision or hearing)",
      text:
        message ||
        'Most Medicare Advantage plans offer extra benefits such as dental, vision and hearing coverage for no additional premium.',
    },
    {
      title:
        "You're indifferent as to whether you plan includes extra benefits (such as dental, vision or hearing)",
      text:
        message ||
        'Most Medicare Advantage plans offer extra benefits such as dental, vision and hearing coverage for no additional premium.',
    },
  ];
};

export const ExplanationMessage = ({ exp }: { exp: Explanation }) => (
  <Typography>
    <Box
      component={'span'}
      sx={{ color: exp.titleColor || '#0B741C', fontWeight: 600 }}
    >
      {exp.title}
    </Box>
    <br />
    {exp.text}
  </Typography>
);

interface Explanation {
  title: string;
  text: string;
  titleColor?: string;
}

//0 - MG, 1 - PPO, 2 - HMO
const values = [
  ['110', '100'],
  ['111', '101', '011', '010'],
  ['000', '001'],
];

export const planNameByType = (type: PlanType) => {
  switch (type) {
    case PlanType.HDMG:
      return 'High Deductible Medicare Supplement';
    case PlanType.MG:
      return 'Medicare Supplement';
    case PlanType.PPO:
      return 'Medicare Advantage PPO';
    case PlanType.HMO:
      return 'Medicare Advantage HMO';
    case PlanType.OM:
      return 'Original Medicare';
    case PlanType.DSNP:
      return 'Medicare Advantage D-SNP';
    case PlanType.MMP:
      return 'Medicare Advantage MMP';
  }
};
